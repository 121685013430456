import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: false
    },
    themes: {
      light: {
        primary: "#17294A",
        secondary: "#e8ecfa",
        accent: "#5C85A8",
        error: "#fd397a",
        info: "#5C85A8",
        success: "#0abb87",
        warning: "#ffb822"
      }
    }
  }
});
